.overlay {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: opacity .4s;
	background-color: var(--bg-overlay-color);
	z-index: 9999;
}

.messageContainer {
	width: 500px;
	background-color: var(--bg1-color);
	border-radius: 15px;
	box-sizing: border-box;
	margin: 30px;
	padding: 39px 40px 43px;
	text-align: center;
	box-shadow: 0px 0px 70px 0px var(--alert-shadow-color);
}

.messageDiv {
	user-select: text;
	-webkit-user-select: text;
	font-size: 14px;
	line-height: 20px;
	color: var(--text-color);
}

.message {
	margin-top: 13px;
	user-select: text;
	-webkit-user-select: text;
}

.buttonContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	margin-top: 30px;
}

.button {
	min-width: 120px;
	padding: 9px 24px 8px !important;
	font-size: 14px !important;
	line-height: 18px !important;
	letter-spacing: .2px;
}

.cancelButton {
	background-color: var(--bg-btn-cancel-color) !important;
}
