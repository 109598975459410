
.wrapper {
	min-height: calc(100vh - 120px);
	margin-left: 70px;
	background-color: var(--bg1-color);
}

@media (max-width: 767px) {

	.wrapper {
		margin-left: 0;
	}
		
}

@media (max-width: 570px) {

	.wrapper {
		min-height: calc(100vh - 215px);
	}
		
}