.sectionHeading {
	font-size: 23px;
	line-height: 26px;
	font-family: 'Greycliff Demibold';
	padding: 34px 24px 31px;
}

.container {
	position: relative;
	padding: 0 30px 55px;
}

.formatContainer {
	position: relative;
	left: calc(50% + 205px);
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 244px;
	margin-top: -244px;
	text-align: left;
}

.format {
	font-size: 23px;
	line-height: 26px;
	font-family: 'Greycliff Demibold';
	margin: 2px 0 2px -.5px;
}

@media (max-width: 767px) {

	.container {
		padding-bottom: 47px;
	}

	.formatContainer {
		left: 0;
		margin-top: 16px;
		width: 100%;
		height: unset;
		text-align: center;
	}			

}