.container {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 1110px;
	min-height: calc(100vh - 323px);
	margin: 0 auto;
	padding: 93px 10px 110px;
	text-align: center;
}

@media (max-width: 570px) {

	.container {
		min-height: calc(100vh - 418px);
	}

}
