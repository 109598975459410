
.wrapper {
	min-height: calc(100vh - 120px);
}

.container {
	text-align: center;
	margin: 0 auto;
}

.background {
	width: 100%;
	background-color: var(--bg0-color);
	min-height: 650px;
}

@media (max-width: 570px) {

	.wrapper {
		min-height: calc(100vh - 215px);
	}

}
