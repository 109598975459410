.container {
	margin-left: 70px;
	padding: 41px 20px 80px;
}

.icon {
	margin: 0 30px -5px -6px;
	opacity: .75;
}

.heading {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	min-height: 100px;
	margin: 0 auto -24px;
	color: var(--text-color);
}

.title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.subHeading {
	margin: 15px 0 33px 10px;
	color: var(--text-color);
}

.infoButton {
	position: relative;
	top: -3px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 1px solid var(--border-medium-color);
	font-size: 12px;
	font-family: 'Greycliff demibold';
	line-height: 12px;
	margin: 0 0 4px 10px;
	padding: 1px 0 0;
	cursor: pointer;
}

.loaderWrap {
	position: relative;
	padding-top: 70px;
}

.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 343px);
	padding-top: 20px;
}

.loaderPct {
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: calc(100vh - 343px);
	padding-top: 52px;
	color: var(--text-color);
}

.sumbitLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 48px;
	padding: 21px 0 0;
}

.submitLoaderPct {
	width: 100%;
	height: 20px;
	margin-bottom: -20px;
	color: var(--text-color);
}

.button {
	margin: 20px 15px 0 !important;
	padding: 0 !important;
	width: 160px !important;
	height: 36px !important;
	font-size: 14px !important;
	letter-spacing: .5px !important;
}

.reportButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 30px;
	margin: 25px auto -38px;
	font-size: 12px;
	font-family: 'Greycliff demibold';
	color: var(--text-color);
	opacity: .5;
	cursor: pointer;
}

.reportButton:hover {
	opacity: 1;
}

.videoContainer {
	width: 1080px;
	margin: 40px auto 0;
}
