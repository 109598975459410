
.textVideoWrapper {
	display: flex;
	justify-content: space-between;
	column-gap: 50px;
	row-gap: 40px;
}

.textWrapper {
	min-width: 250px;
	max-width: 350px;
}

.title {
	margin: -4px 0 12px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 22px;
	color: var(--text-color)
}

.description {
	font-size: 14px;
	line-height: 20px;
	color: var(--text-color);
}

.videoWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-width: 350px;
	max-width: 422px;
	background-color: var(--bg1-color);
}

.canvas {
	display: block;
	width: 100%;
	border: 1px solid var(--border-video-color);
}

.loaderContainer {
	position: absolute;
}

.controlsContainer {
	width: calc(100% - 60px);
	margin: 0 15px 0 45px;
}

.lineContainer {
	height: 17px;
	display: flex;
	align-items: center;
	margin: 39px -15px 0;
}

.line {
	width: 100%;
	height: 1px;
	background-color: var(--border-light-color)
}

.draggerContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 30px;
	height: 284px;
	margin-left: -15px;
	background-repeat: no-repeat;
	background-position: center 9px;
	background-image: url('../../../../assets/images/graph-cursor-line.svg');
	z-index: 500;
}

.time {
	position: relative;
	top: -47px;
	margin-left: -35px;
	width: 100px;
	height: 20px;
	text-align: center;
	color: var(--text-color);
}

.dragger {
	position: relative;
	top: -43px;
	width: 30px;
	height: 30px;
	margin-bottom: 147px;
	background-image: url('../../../../assets/images/video-play.svg');
	cursor: pointer;
	transition: background-image .3s;
}

.scoreWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 9px;
	margin-bottom: 7px;
}

.scoreBox {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 29px;
	height: 22px;
	box-sizing: border-box;
	padding-top: 1px;
	border-radius: 3px;
	font-family: 'Greycliff demibold';
	font-size: 15px;
	color: var(--text-inverse-color);
}

.scoreLabel {
	display: inline;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	white-space: nowrap;
	color: var(--text-color);
	opacity: .8;
}

.scoreMarker {
	position: absolute;
	left: 12px;
	min-width: 6px;
	min-height: 6px;
	border-radius: 3px;
}

.colorClarity {
	background-color: var(--clarity-color);
}

.colorBrand {
	background-color: var(--brand-color);
}

.colorComms {
	background-color: var(--comms-color);
}

.graphContainer {
	width: 100%;
	height: 200px;
	max-width: 870px;
	margin: -244px auto 68px;
}

@media (max-width: 899px) {

	.textVideoWrapper {
		flex-wrap: wrap;
	}

	.textWrapper {
		max-width: 422px;
	}
	.videoWrapper {
		min-width: initial;
	}
		
}
