
.uploadContainer {
	display: flex;
	align-items: center;
}

.uploadText {
	margin-left: 26px;
	min-width: 120px;
	max-width: 220px;
	font-size: 14px;
	line-height: 20px;
}

.dropZone {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 120px;
	height: 120px;
	border: 2px solid;
	border-radius: 50%;
	border-style: dashed;
	border-color: var(--dropzone-border-color);
	font-size: 14px;
	line-height: 20px;
	padding: 19px;
	box-sizing: border-box;
	cursor: pointer;
	margin-top: -127px;
}

.logoCanvas {
	border-radius: 50%;
}

.status {
	pointer-events: none;
}