.graphWrapper {
	display: flex;
	flex-direction: column;
}

.graphScale {
	display: flex;
	align-items: center;
	column-gap: 10px;
	min-height: 16px;
}

.scaleLabel {
	height: 12px;
	min-Width: 20px;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	line-height: 12px;
	text-align: right;
	color: var(--text-label-blue);
}

.scaleLine {
	width: 100%;
	height: 1px;
	opacity: .4;
	background-color: var(--text-color);
}

.midLine {
	opacity: .1;
	margin: 47px 0;
}

.averageWrapper {
	display: flex;
	margin-top: -127px;
	height: 127px;
	padding: 0 15px 0 45px;
}

.averageMarker {
	position: relative;
	min-width: 50px;
	height: 130px;
	margin: -12px -25px 0;
	background-repeat: no-repeat;
	background-position: center 15px;
	background-image: url('../../../../../assets/images/graph-secs-line.svg');
}

.averageSecsLabel {
	height: 12px;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	color: var(--text-label-blue);
}

.averageSecsLabel:after {
	content: ' sec'
}

.scoreBox {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 18px;
	height: 13px;
	box-sizing: border-box;
	margin: 0 auto -13px;
	padding-top: 1px;
	border-radius: 2px;
	font-family: 'Greycliff demibold';
	font-size: 10px;
	color: var(--text-inverse-color);
	z-index: 598;
}

.colorClarity {
	background-color: var(--clarity-color);
}

.colorBrand {
	background-color: var(--brand-color);
}

.colorComms {
	background-color: var(--comms-color);
}

.canvasWrapper {
	margin-top: -119px;
	padding: 0 15px 0 45px;
}

.canvas {
	width: 100%;
	height: 111px;
	display: block;
}

.settingsButton {
	width: 30px;
	height: 26px;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('../../../../../assets/images/icon-graph-settings.svg');
	margin-left: auto;
	cursor: pointer;
	z-index: 599;
}

.settingsWrapper {
	margin-left: auto;
	border-radius: 8px 0 8px 8px;
	background-color: var(--bg0-color);
	z-index: 600;
}

.settingsContainer {
	padding: 13px 15px 8px 16px;
	box-sizing: border-box;
	border-radius: 8px;
	text-align: right;
	background-color: var(--bg1-color);
	display: none;
}

.switchContainer {	
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 9px;
	margin-bottom: 7px;
}

.switchLabel {
	color: var(--text-color);
	cursor: pointer;
}

.showSettings {
	display: block;
}

@media (max-width: 899px) {

	.averageSecsLabel:after {
		content: ' s'
	}
		
}