.wrapper {
	width: 198px;
}

.topBar {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	padding: 5px;
	box-sizing: border-box;
	border-radius: 8px 8px 0 0;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	color: var(--text-inverse-color);
}

.contentWrapper {
	padding: 18px 30px 10px;
	border-radius: 0 0 8px 8px;
	background-color: var(--bg0-color);
}

.scoreBox {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 29px;
	height: 22px;
	box-sizing: border-box;
	padding-top: 1px;
	border-radius: 3px;
	font-family: 'Greycliff demibold';
	font-size: 15px;
	color: var(--text-inverse-color);
}

.scoreWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 15px;
	margin-bottom: 10px;
}

.noAsset {
	justify-content: center;
}

.scoreLabel {
	font-family: 'Greycliff demibold';
	font-size: 12px;
	color: var(--text-color);
	opacity: .8;
}

.assetLabel {
	height: 21px;
	margin: 20px 0 17px;
	padding: 2px 8px 0;
	background-color: var(--bg1-color);
	font-family: 'Greycliff demibold';
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-color);
}

.imageWrapper {
	height: 138px;
	margin: -13px 0 14px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-color: var(--bg1-color);
	cursor: pointer;
}

.colorViewTime {
	background-color: var(--bg4-color);
}

.colorClarity {
	background-color: var(--clarity-color);
}

.colorBrand {
	background-color: var(--brand-color);
}

.colorComms {
	background-color: var(--comms-color);
}

.overlay {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--bg-overlay-color);
	z-index: 9999;
	cursor: pointer;
}

.fullImage {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	max-width: 640px;
	height: 100%;
	max-height: 640px;
}

