.background {
	padding: 75px 85px 52px;
	background-color: var(--bg1-color);
}

.header {
	display: flex;
	justify-content: space-between;
	max-width: 1040px;
	margin: 0 auto 77px;
}

.heading {
	padding-bottom: 2px;
	font-size: 36px;
	line-height: 42px;
	color: var(--text-color)
}

.logo {
	width: 231px;
	margin: -3px 0 20px;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18vw;
	max-width: 198px;
	height: 18vw;
	max-height: 198px;
	border-radius: 8px;
	margin-top: 3px;
	margin-bottom: 60px;
	background-color: var(--bg3-color);
}

.icon img {
	transform: scale(1.9)
}

.detailsWrapper {
	display: flex;
	flex-wrap: wrap;
	column-gap: 85px;
	max-width: 1040px;
	margin: 0 auto;
}

.detailsGroupsWrapper {
	display: flex;
	flex-wrap: wrap;
	column-gap: 85px;
	margin-bottom: 40px;
}

.detail {
	display: flex;
}

.detailLabel {
	min-width: 96px;
	min-height: 36px;
	padding-top: 1px;
	font-size: 14px;
	opacity: .8;
	color: var(--text-color);
}

.detailText {
	margin-bottom: 14px;
	font-size: 18px;
	line-height: 23px;
	max-width: 270px;
	color: var(--text-color);
}

@media (max-width:900px) {

	.header {
		flex-direction: column;
		flex-wrap: wrap;
		margin: 0 auto 37px;
	}

	.heading {
		order: 2;
	}

	.logo {
		margin-right: 15px;
		order: 1;
	}

	.icon {
		display: none;
	}
	
}

@media (max-width:767px) {

	.background {
		padding: 65px 40px 22px;
	}

	.heading {
		font-size: 30px;
		line-height: 34px;
	}	
}

@media (max-width: 429px) { /* smaller than iPhone 15 Pro max */

	.background {
		padding: 55px 30px 12px;
	}
			
}