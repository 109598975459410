.wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 48px;
}

.label {
	min-width: 198px;
	margin-bottom: 2px;
	font-size: 23px;
	color: var(--text-color);
}

.barWrapper {
	width: 100%;
}

.barBack {
	background-color: var(--bg0-color);
	height: 11px;
	border-radius: 10px;
}

.bar {
	height: 11px;
	border-radius: 10px;
}

.scoreBoxWrapper {
	position: relative;
	margin: 0 7px;
}

.scoreBox {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -21px;
	min-width: 40px;
	height: 31px;
	box-sizing: border-box;
	margin-left: -20px;
	padding: 1px 5px 0;
	border-radius: 5px;
	font-family: 'Greycliff demibold';
	font-size: 25px;
	color: var(--text-inverse-color);
}

.norm {
	position: absolute;
	top: -50px;
	min-width: 20px;
	box-sizing: border-box;
	margin-left: -10px;
	background-repeat: no-repeat;
	background-position: center bottom;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	text-align: center;
	color: var(--text-label-blue);
}

.userNorm {
	height: 27px;
	background-image: url('../../../../assets/images/icon-norm-user.svg');
	background-size: 11px;
}

.industryNorm {
	height: 26px;
	background-image: url('../../../../assets/images/icon-norm-industry.svg');
	background-size: 8px;
}

.competitorNorm {
	height: 28px;
	background-image: url('../../../../assets/images/icon-norm-competitors.svg');
	background-size: 10px;
}

.smallLabel {
	margin: 1px 0 0 10px;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	color: var(--text-label-blue);
}

.colorClarity {
	background-color: var(--clarity-color);
}

.colorBrand {
	background-color: var(--brand-color);
}

.colorComms {
	background-color: var(--comms-color);
}

@media (max-width: 767px) {

	.label {
		min-width: 170px;
		font-size: 20px;
	}

	.smallLabel {
		display: none;
	}
	
}