.container {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	margin: 0 15px 30px;
	padding: 30px 35px 28px;
	background-color: var(--bg0-color);
	width: 340px;
	min-height: 354px;
	box-sizing: border-box;
	border-radius: 10px;
	text-align: left;
}

.closeButton {
	position: absolute;
	top: 12px;
	right: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 2px;
	background-color: var(--bg1-color);
	font-size: 24px;
	color: var(--text-label-blue);
	box-sizing: border-box;
	width: 20px;
	height: 20px;
	border-radius: 11px;
	cursor: pointer;
}

.multiColumn {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	column-gap: 14px;
}

.distribute {
	flex: 1;
}

.inputLabel {
	font-size: 12px;
	line-height: 15px;
	color: var(--text-color);
	opacity: .6;
	padding-left: 2px;
	margin-bottom: 3px;
}

.inputText {
	border: none;
	border-radius: 8px;
	background-color: var(--bg1-color);
	width: 100%;
	height: 31px;
	padding: 0 12px;
	box-sizing: border-box;
	color: var(--text-color);
	font-size: 14px;
	line-height: 20px;
	appearance: none;
}

.switchContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 10px;
	width: 100%;
	height: 38px;
	margin-top: 11px;
	color: var(--text-color);
	font-size: 14px;
	line-height: 16px;
}

.switchContainer .enabled {
	opacity: 1;
	cursor: default;
	pointer-events: none;
	transition: opacity .4s;
}

.switchContainer .disabled {
	opacity: .6;
	cursor: pointer;
	pointer-events: all;
	transition: opacity .4s;
}

.button {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 31px;
	border-radius: 25px;
	margin: 0 auto;
	padding-top: 1px;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
	transition: opacity .3s, background-color .3s;
}

.more {
	background-color: var(--bg1-color);
	color: var(--text-color);
	padding-top: 0;
} 

.submit {
	background-color: var(--bg4-color);
	color: var(--text-inverse-color);
	font-family: 'Greycliff Demibold';
}

.submit:hover {
	background-color: var(--bg5-color);
}

.submit.disabled {
	opacity: .3;
	cursor: default;
	background-color: var(--bg4-color);
}

.moreDescription {
	font-size: 12px;
	line-height: 15px;
	color: var(--text-color);
	opacity: .6;
	padding-left: 2px;
	margin: 3px 0 6px;
}

.moreContainer {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	padding-top: 10px;
}

.selectWrapper {
	display: flex;
	height: 31px;
	padding: 0 12px;
	background-color: var(--bg1-color);
	border-radius: 8px;
}

.selectWrapper:after {
	border: solid var(--text-color);
	border-width: 0 1.45px 1.45px 0;
	display: inline-block;
	height: 5px;
	padding-left: 5px;
	margin: 10.5px 0 0 -8px;
	transform: rotate(45deg);
	content: '';
	pointer-events: none;
}

.select {
	display: block;
	appearance: none;
	background-color: transparent;
	border: none;
	padding-right: 22px;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: var(--text-color);
	font-size: 14px;
	cursor: pointer;
}