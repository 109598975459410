.wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}

.col2 {
	width: 116px;
	min-width: 116px;
	padding-right: 10px;
	box-sizing: border-box;
}

.col3 {
	display: flex;
	width: 100%;
	min-width: 60px;
	margin-right: 35px;
}

.col4 {
	min-width: 110px;
}

.imageWrapper {
	width: 60px;
	height: 60px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-color: var(--bg0-color);
	border: 1px solid var(--border-light-color);
	margin-right: 20px;
	cursor: pointer;
}

.noImage {
	width: 62px;
	height: 62px;
	margin-right: 20px;
}

.label {
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-color);
}

.editableLabel {
	width: 100%;
	height: 19px;
	margin: 0;
	padding: 0;
	border: none;
	text-align: left;
	font-size: 14px;
	background-color: rgba(0,0,0,0);
	color: var(--text-color);
	-webkit-appearance: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.selectWrapper {
	display: flex;
	height: 20px;
	background-color: var(--bg0-color);
}

.selectWrapper:after {
	border: solid var(--text-color);
	border-width: 0 1.45px 1.45px 0;
	display: inline-block;
	height: 5px;
	padding-left: 5px;
	margin: 5.5px 8px 0 -15px;
	transform: rotate(45deg);
	content: '';
	pointer-events: none;
}

.select {
	display: block;
	appearance: none;
	background-color: transparent;
	border: none;
	padding-left: 8px;
	padding-right: 22px;
	width: 110px;
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: var(--text-color);
	font-size: 12px;
	cursor: pointer;
}

.unselected {
	color: var(--text-label-blue);
}

.barWrapper {
	width: 100%;
	margin-top: 7px;
	padding: 0 15px;
	height: 15px;
}

.barBack {
	background-color: var(--bg0-color);
	height: 8px;
	margin: 0 -15px;
	border-radius: 10px;
}

.bar {
	height: 8px;
	border-radius: 10px;
}

.scoreBoxWrapper {
	position: relative;
}

.scoreBox {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -15px;
	min-width: 29px;
	height: 22px;
	box-sizing: border-box;
	margin-left: -15px;
	padding: 1px 3px 0;
	border-radius: 3px;
	font-family: 'Greycliff demibold';
	font-size: 15px;
	color: var(--text-inverse-color);
}

.smallLabel {
	margin: 2px 0 0 10px;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	color: var(--text-label-blue);
}

.colorBrand {
	background-color: var(--brand-color);
}

.colorComms {
	background-color: var(--comms-color);
}

.overlay {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--bg-overlay-color);
	z-index: 9999;
	cursor: pointer;
}

.fullImage {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	max-width: 640px;
	height: 100%;
	max-height: 640px;
}

@media (max-width: 767px) {

	.col2 {
		width: 100px;
		min-width: 100px;
	}
	
	.col3 {
		margin-right: 0;
	}

	.smallLabel, .col4 {
		display: none;
	}

	.noImage {
		width: 0;
		margin-right: 0;
	}
	
}