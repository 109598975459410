.scoreWrapper {
	position: relative;
}

.circleBg {
	fill: none;
	stroke: var(--progressbar-back-color);
}

.scoreText {
	fill: var(--text-color);
	font-family: 'Greycliff demibold';
	text-anchor: middle;
}

.labelText {
	line-height: 18px;
	color: var(--text-color);
}

.circle {
	fill: none;
	stroke-linecap: round;
	transform-origin: center;
	transform: rotate(4.75deg);
}
