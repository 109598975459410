.container {
	display: flex;
	flex-direction: column;
	flex: 1 340px;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	background-color: var(--bg1-color);
	border-radius: 10px;
	overflow: hidden;
}

.heading {
	margin: 22px 24px 15px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 19px;
	text-align: center;
}

.graphContainer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 140px;
	margin: 0 24px 21px;
	padding: 17px 7.5% 17px;
	border-radius: 8px;
	background-color: var(--bg-dashboard-item-color);
	box-sizing: border-box;
}

.graphMonth {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	min-width: 14px;
	height: 100%;
	font-size: 12px;
}

.count {
}

.graphBar {
	width: 8px;
	min-height: 1px;
	margin-bottom: 3px;
	background-color: var(--bg4-color);
	opacity: .6;
}

.month {
	opacity: .6;
}

@media (max-width: 1010px) {
	
	.container {
		flex: auto;
	}

}
