.actionContainer {
	width: 102px;
	position: absolute;
	right: 0;
	bottom: 125px;
}

.actionDots {
	width: 3px;
	padding: 9px 10px 7px;
	float: right;
	opacity: .4;
	transition: opacity .2s;
}

.actionDots:hover {
	opacity: 1;
}

.actionMenuItem {
	height: 32px;
	padding-left: 14px;
	color: var(--text-color);
	background-color: var(--bg1-color);
	border-bottom: 1px solid var(--bg0-color);
	display: flex;
	align-items: center;
	box-sizing: border-box;
	user-select: none;
	transition: background-color .2s;
}

.actionMenuItem:hover {
	background-color: var(--bg0-color);
}

.dot {
	width: 3px;
	height: 3px;
	border-radius: 3px;
	margin-bottom: 3px;
	background-color: var(--text-color);
}
