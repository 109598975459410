
.accountElementBack {
	padding: 53px 50px 70px;
	border-top: 1px solid var(--border-light-color);
}

.billingContainer {
	max-width: 1080px;
	margin: 0 auto;
	box-sizing: border-box;
	text-align: center;
	font-size: 18px;
	line-height: 26px;
}

.billingBox {
	display: flex;
	flex-wrap: wrap;
	column-gap: 50px;
	row-gap: 4px;
	background-color: var(--bg1-color);
	margin: 27px 0 40px;
	padding: 0 30px 30px;
	border-radius: 10px;
}

.billingBoxLeft {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-width: 300px;
	text-align: left;
}

.billingBoxRight {
	display: flex;
	flex-direction: column;
	flex: 1;
	row-gap: 13px;
	min-width: 300px;
	margin: 22px 0 4px;
}

.billingFields {
	display: flex;
	flex-direction: column;
	row-gap: 13px;
}

.inputLabel {
	padding-left: 2px;
	margin-bottom: 3px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	color: var(--text-color);
}

.inputText {
	border: none;
	border-radius: 8px;
	background-color: var(--bg0-color);
	width: 100%;
	height: 40px;
	padding: 0 16px 1px;
	box-sizing: border-box;
	color: var(--text-color);
	font-size: 18px;
	line-height: 24px;
	-webkit-appearance: none;
	appearance: none;
}

@media (max-width: 767px) {

	.accountElementBack {
		padding: 53px 30px 70px;
	}

}

@media (max-width:440px) {

	.accountElementBack {
		padding: 53px 0px 70px;
	}

	.billingBox {
		border-radius: 0;
	}

	.billingBoxLeft {
		min-width: 260px;
		text-align: left;
	}
	
	.billingBoxRight {
		min-width: 260px;
	}
}