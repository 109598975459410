.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	background-color: var(--bg1-color);
	border-radius: 10px;
	overflow: hidden;
}

.heading {
	margin: 22px 24px 15px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 19px;
	text-align: center;
}

.videoTeaser {
	flex: 1;
	margin: 0 24px 21px;
	border-radius: 8px;	
	background-image: url('../../../assets/images/introvideo-cover.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	cursor: pointer;
}

.playButton {
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/video-play.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 40px;
	opacity: .8;
}

.overlay {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0 100px;
	box-sizing: border-box;
	background-color: var(--bg-overlay-color);
	z-index: 9999;
	cursor: pointer;
	transition: padding .5s;
}

.fullVideo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 1200px;
}

@media (max-width: 767px) {

	.overlay {
		padding: 0;
	}
	
}