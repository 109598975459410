.login {
	min-height: calc(100vh - 120px);
	height: 380px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

.heading {
	padding-bottom: 40px;
	text-align: center;
}

.inputGroup {
	width: 100%;
	max-width: 340px;
	padding: 0 30px;
	box-sizing: border-box;
}

.inputLabel {
	padding-left: 2px;
	margin-bottom: 3px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	color: var(--text-color);
}

.inputText {
	border: none;
	border-radius: 8px;
	background-color: var(--bg1-color);
	width: 100%;
	height: 40px;
	margin-bottom: 10px;
	padding: 0 16px 1px;
	box-sizing: border-box;
	color: var(--text-color);
	font-size: 18px;
	line-height: 24px;
	-webkit-appearance: none;
	appearance: none;
}

@media (max-width: 570px) {

	.login {
		min-height: calc(100vh - 215px);
	}

}
