.infoGroup {
	position: relative;
}

.infoWrapper {
	padding: 6px;
	border-radius: 10px;
}

.info {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	padding-top: 1px;
	border: 1px solid var(--border-medium-color);
	border-radius: 50%;
	background-color: var(--bg0-color);
	font-family: 'Greycliff Demibold';
	font-size: 12px;
	line-height: 12px;
	box-sizing: border-box;
}

.infoInverse {
	border: 1px solid var(--border-inverse-color);
	background-color: transparent;
	color: var(--text-inverse-color);
}

.infoText {
	position: absolute;
	top: -2px;
	left: 31px;
	width: max-content;
	max-width: 150px;
	padding: 9px 13px 11px 15px;
	border: 1px solid var(--border-medium-color);
	border-radius: 8px;
	background-color: var(--bg0-color);
	font-family: 'Greycliff';
	font-size: 12px;
	line-height: 15px;
	text-align: left;
	box-sizing: border-box;
	opacity: 0;
	transition: opacity .3s;
	pointer-events: none;
	z-index: 9901;
}