.background {
	padding: 75px 85px 31px;
}

.header {
	display: flex;
	justify-content: space-between;
	max-width: 1040px;
	margin: 0 auto 26px;
}

.heading {
	display: flex;
	align-items: flex-end;
	padding-bottom: 2px;
	font-size: 36px;
	line-height: 42px;
	color: var(--text-color)
}

.switchesContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: -8px;
}

.switchContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 9px;
	margin-bottom: 6px;
	color: var(--text-color);
	cursor: pointer;

}

.videoWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 1040px;
	background-color: var(--bg1-color);
}

.canvas {
	display: block;
	width: 100%;
	border: 1px solid var(--border-video-color);
}

.loaderContainer {
	position: absolute;
}

.loaderPct {
	text-align: center;
	margin-top: 6px;
	color: var(--text-color);
}

.controlsContainer {
	width: calc(100% - 30px);
	max-width: 1010px;
	margin: 0 auto;
}

.lineContainer {
	height: 17px;
	display: flex;
	align-items: center;
	margin: 39px -15px 0;
}

.line {
	width: 100%;
	height: 1px;
	background-color: var(--border-light-color)
}

.dragger {
	position: relative;
	top: -23px;
	left: 0;
	margin-left: -15px;
	width: 30px;
	height: 30px;
	background-image: url('../../../assets/images/video-play.svg');
	cursor: pointer;
	transition: background-image .3s;
	z-index: 500;
}

.time {
	position: relative;
	top: -77px;
	margin-left: -50px;
	width: 100px;
	text-align: center;
	color: var(--text-color);
}

@media (max-width: 900px) {

	.header {
		flex-direction: column;
	}

	.switchesContainer {
		align-items: flex-start;
		margin-top: 8px;
	}
	
}

@media (max-width:767px) {

	.background {
		padding: 65px 40px 21px;
	}
	
	.heading {
		font-size: 30px;
		line-height: 34px;
	}	

}

@media (max-width: 429px) { /* smaller than iPhone 15 Pro max */

	.background {
		padding: 55px 30px 11px;
	}
			
}