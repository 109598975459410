.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	gap: 30px;
}

.container {
	display: flex;
	flex-direction: column;
	background-color: var(--bg1-color);
	border-radius: 10px;
	overflow: hidden;
}

.heading {
	margin: 22px 24px 12px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 19px;
	text-align: center;
}

.status {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 9px;
	margin-left: -9px;
	border-radius: 50%;
}

.statusOk {
	background-color: var(--bg3-color)
}

.statusErr {
	background-color: var(--error-color)
}

.statusText {
	font-size: 14px;
	line-height: 20px;
	margin: 0 16% 28px;
	text-align: center;
}

.cta {
	margin: 0 24px 24px;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	line-height: 16px;
	color: var(--text-label-green);
	text-align: center;
}

