.averagesContainer {
	display: flex;
	flex: 1;
	gap: 4px;
}

.averageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	padding: 14px 0 4px;
}

.scoreBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 62px;
	height: 46px;
	margin-bottom: 8px;
	padding-top: 2.5px;
	box-sizing: border-box;
	border-radius: 7px;
	font-family: 'Greycliff demibold';
	font-size: 36px;
	line-height: 40px;
	color: var(--text-inverse-color);
}

.scoreBox.na {
	padding-top: 0;
	font-size: 25px;
}

.clarity .scoreBox {
	background-color: var(--clarity-color);
}

.brand .scoreBox {
	background-color: var(--brand-color);
}

.comms .scoreBox {
	background-color: var(--comms-color);
}

.scoreLabel {
	text-align: center;
	line-height: 18px;
	white-space: nowrap;
}

@media (max-width: 1010px) {

	.averagesContainer {
		flex-direction: column;
		gap: 0;
	}

	.scoreBox {
		width: 56px;
		height: 42px;
		margin-bottom: 7px;
		border-radius: 6px;
		font-size: 32px;
		line-height: 36px;
	}
	
}
