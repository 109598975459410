.back {
	width: 20px;
	height: 20px;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.checked {
	background-image: url('../../../../assets/images/icon-norm-checkmark.svg');
}