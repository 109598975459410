.barContainer {
	display: flex;
	margin-top: -18px;
	transition: padding-left .3s;
}

.objectContainer {
	position: relative;
}

.objWrapper {
	position: absolute;
	z-index: 700;
}

.object {
	position: absolute;
	box-sizing: border-box;
	margin-top: -2px;
	cursor: pointer;
	box-shadow: 0 0 80px 0 rgba(0,0,0,0.06);
}

.object2 {
	position: absolute;
	margin-top: -2px;
	box-shadow: 0 0 80px 0 rgba(0,0,0,0.05) inset;
}

.objectTitle {
	position: relative;
	width: 0;
	height: 17px !important;
	border-radius: 5px 5px 0 0;
	pointer-events: auto;
}

.measureTitle {
	width: auto !important;
	height: 17px !important;
	line-height: 18px;
	padding: 0 8px 1px !important;
	border-radius: 5px 5px 0 0;
	white-space: pre;
}

.selectWrapper {
	display: flex;
	height: 17px;
	margin-left: 2px;
}

.select {
	position: absolute;
	display: none;
	appearance: none;
	border: none;
	height: 17px;
	padding-left: 8px;
	text-align: left;
	border-radius: 6px 6px 0 0;
	font-size: 12px;
	cursor: pointer;
}

.selectWidth {
	position: absolute;
	height: 0;
	font-size: 12px;
	font-family: 'Greycliff demibold';
	white-space: nowrap;
}

.selectLabel {
	position: absolute;
	padding: 0 8px 0 21px;
	height: 17px;
	border-radius: 5px 5px 0 0;
	font-size: 12px;
	line-height: 18px;
	font-family: 'Greycliff demibold';
	pointer-events: none;
	white-space: nowrap;
}

.selectLabel:before {
	border: solid var(--text-inverse-color);
	border-width: 0 1.5px 1.5px 0;
	display: inline-block;
	height: 5px;
	padding-left: 5px;
	margin: 0 6px 2px -13px;
	transform: rotate(45deg);
	content: '';
	pointer-events: none;
}

.unselected {
	padding-right: 2.5px;
}