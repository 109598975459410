.title {
	font-family: 'Greycliff demibold';
	margin-top: 10px;
	width: 300px;
	text-shadow: 0 0 40px var(--text-shadow-color);
	color: var(--text-inverse-color);
	font-size: 25px;
	line-height: 31px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
 }

.project {
	line-height: 32px;
	width: 300px;
	text-shadow: 0 0 40px var(--text-shadow-color);
	color: var(--text-inverse-color);
 }

.brand {
	line-height: 8px;
 }

.date {
	font-size: 12px;
	margin-bottom: -20px;
	opacity: 0;
	transition: opacity .2s;
	color: var(--text-inverse-color);
 }

.infoArea {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 160px;
 }

.tagButton {
	padding: 6px 20px 5px !important;
	margin: -6px 0 8px !important;
	font-size: 14px !important;
	letter-spacing: .2px !important;
 }

.titleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	padding: 20px;
	box-sizing: border-box;
	background-color: var(--bg-list-item-overlay-color);
	overflow: hidden;
 }

.image {
	width: 100%;
	height: 194px;
	background-repeat: no-repeat;
	background-position: center;
 }

.item {
	position: relative;
	background-color: var(--bg0-color);
	width: 340px;
	height: 354px;
	margin: 0 15px 30px;
	border-radius: 10px;
	overflow: hidden;
 }

 .analyzingContainer {
	width: 260px;
}

.progressText {
	margin: -2px 0 8px;
	color: var(--text-color);
}

.readyText {
	margin: -2px 0 26px;
	color: var(--text-color);
	line-height: 20px;
}