
.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 40px;
	padding: 0 65px 49px;
}

.sectionHeading {
	font-size: 23px;
	line-height: 26px;
	font-family: 'Greycliff Demibold';
	padding: 34px 24px 31px;
}

.ctaBoxContainer {
	flex: 1;
	min-width: 260px;
	max-width: 350px;
}

.ctaBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 244px;
	padding: 37px 30px 30px;
	box-sizing: border-box;
	background-image: url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'10\' ry=\'10\' stroke=\'%232d354466\' stroke-width=\'4\' stroke-dasharray=\'1%2c 11\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");
	background-color: var(--bg1-color);
	border-radius: 10px;
	color: var(--text-color);
}

.product {
	font-size: 23px;
	line-height: 26px;
	margin-bottom: 7px;
	white-space: nowrap;
}

.price {
	font-size: 36px;
	line-height: 42px;
	margin-bottom: 30px;
	font-family: 'Greycliff demibold';
}

.ctaBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 216px;
	height: 48px;
	border-radius: 25px;
	margin: 0 auto;
	background-color: var(--bg4-color);
	color: var(--text-inverse-color);
	font-family: 'Greycliff Demibold';
	font-size: 17px;
	line-height: 22px;
	cursor: pointer;
	transition: background-color .25s;
}

.ctaBtn:hover {
	background-color: var(--bg5-color);
}

.description {
	text-align: center;
	max-width: 320px;
	margin: 19px auto 0;
}

@media (max-width: 767px) {

	.container {
		padding: 0 30px 49px;
	}
			
}