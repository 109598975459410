.sectionHeading {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
	font-size: 23px;
	line-height: 26px;
	font-family: 'Greycliff Demibold';
	padding: 33px 0 30px 29px;
	border-top: 1px solid var(--border-light-color);
}

.infoBubble {
	opacity: 0;
	transition: opacity .5s
}

.infoBubble.show {
	opacity: 1;
}

.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0 40px 55px;
}

.industry {
	width: 100%;
	max-width: 468px;
	margin-bottom: 3px;
	background-color: var(--bg1-color);
	border-radius: 10px;
	transition: background-color .2s;
}

.industry:hover {
	background-color: var(--bg2-color);
}

.industry.nohover:hover {
	background-color: var(--bg1-color);
}

.industryLabel {
	display: flex;
	align-items: center;
	min-height: 50px;
	padding: 13px 24px 14px;
	box-sizing: border-box;
	text-align: left;
	font-size: 18px;
	line-height: 23px;
	cursor: pointer;
}

.industryLabel.nopointer {
	cursor: default;
}

.subIndustry {
	display: flex;
	justify-content: space-between;
	margin: 0 24px 3px;
	background-color: var(--bg-dashboard-item-color);
	background-repeat: no-repeat;
	border-radius: 8px;
	transition: background-color .2s;
	cursor: pointer;
}

.subIndustry:last-child {
	margin: 0 24px 24px;
}

.subIndustry:hover {
	background-color: var(--bg0-color);
}

.subIndustryInverse {
	background-color: var(--bg3-color);
	cursor: default;
}

.subIndustryInverse:hover {
	background-color: var(--bg3-color);
}

.subIndustryLabel {
	display: flex;
	align-items: center;
	min-height: 49px;
	padding: 13px 4px 14px 63px;
	box-sizing: border-box;
	text-align: left;
	font-size: 18px;
	line-height: 22px;
}

.subIndustryLabelInverse {
	color: var(--text-inverse-color)
}