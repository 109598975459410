.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	background-color: var(--bg1-color);
	border-radius: 10px;
	overflow: hidden;
}

.thumb {
	height: 178px;
	background-position: center;
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	height: 100%;
	padding-top: 8px;
}

.noAnalysesHeading {
	margin: 22px 24px 15px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 19px;
	text-align: center;
}

.noAnalyses {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 155px;
	margin: 0 24px;
	padding-top: 0px;
	box-sizing: border-box;
	border-radius: 8px;
	background-color: var(--bg-dashboard-item-color);
	text-align: center;
}

.thumbText {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	background-color: rgba(0,0,0,.20);
	height: 100%;
	padding: 20px;
}

.brand {
	margin-bottom: 5px;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	color: var(--text-inverse-color);
	text-shadow: rgba(0, 0, 0, 0.8) 0px 0px 40px;
}

.title {
	margin-bottom: 6px;
	font-family: 'Greycliff demibold';
	font-size: 25px;
	line-height: 29px;
	text-align: center;
	color: var(--text-inverse-color);
	text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 40px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;

}

.date {
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: var(--text-inverse-color);
	text-shadow: rgba(0, 0, 0, 0.8) 0px 0px 40px;
}

.scoreText {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	height: 66px;
	padding: 7px 24px 9px;
	box-sizing: border-box;
}

.labelWrapper {
	max-height: 39px;
	overflow: hidden;
}

.scoreLabel {
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 20px;
}

.cta {
	margin-bottom: -2px;
	font-family: 'Greycliff demibold';
	font-size: 12px;
	line-height: 19px;
	color: var(--text-label-green);
}

.scoreWrapper {
	display: flex;
	gap: 12px;
}

.scoreBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34px;
	height: 27px;
	padding-top: .5px;
	box-sizing: border-box;
	border-radius: 4px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 21px;
	color: var(--text-inverse-color);
}

.scoreClarity {
	background-color: var(--clarity-color);
}

.scoreBrand {
	background-color: var(--brand-color);
}

.scoreComms {
	background-color: var(--comms-color);
}