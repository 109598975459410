.wrapper {
	min-height: calc(100vh - 120px);
	margin-left: 70px;
	padding-top: 70px;
	padding-bottom: 50px;
	box-sizing: border-box;
}

.wrapper h2 {
	margin: 0 auto 11px;
	text-align: center;
	color: var(--text-color);
}

.wrapper h3 {
	flex: 1;
	width: 100%;
	margin-bottom: 20px;
	padding-top: 18px;
	font-size: 23px;
	line-height: 26px;
	text-align: center;
	font-family: 'Greycliff demibold';
	color: var(--text-color);
}

.infoButton {
	position: relative;
	top: -5px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 1px solid var(--border-medium-color);
	font-size: 12px;
	font-family: 'Greycliff demibold';
	line-height: 12px;
	margin: 0 -25px 4px 10px;
	padding: 1px 0 0;
	cursor: pointer;
}

.headingGroup {
	display: flex;
	gap: 30px;
	margin: 0 auto;
	padding: 0 65px;
	max-width: 1080px;
}

.industryYearFilter {
	flex: 1;
	padding-top: 18px;
}

.menuGroupWrapper {
	display: flex;
	gap: 15px;
}

.mediaTypeFilter {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	flex: 1;
	padding-top: 18px;
}

.selectWrapper {
	display: flex;
	align-self: flex-end;
	height: 20px;
	margin: 6px -4px 0 0;
}

.selectWrapper:after {
	border: solid var(--text-color);
	border-width: 0 1.5px 1.5px 0;
	display: inline-block;
	height: 5px;
	padding-left: 5px;
	margin: 5.5px 8px 0 -15px;
	transform: rotate(45deg);
	content: '';
	pointer-events: none;
}

.select {
	display: block;
	appearance: none;
	background-color: transparent;
	border: none;
	padding-left: 3px;
	padding-right: 22px;
	color: var(--text-color);
	font-size: 12px;
	cursor: pointer;
}

.selectWidth {
	position: absolute;
	height: 0;
	overflow: hidden;
	font-size: 12px;
	color: var(--text-inverse-color)
}

.selected {
	font-family: 'Greycliff demibold';
}

.cardGroup {
	display: flex;
	gap: 30px;
	margin: 0 auto 30px;
	padding: 0 65px;
	max-width: 1080px;
}

.cardGroupTour {
	display: flex;
	gap: 30px;
	margin: 0;
	padding: 0;
	width: 100%;
}

.reset {
	min-width: 20px;
	height: 20px;
	margin: 6px -3px -6px -2px;
	border-radius: 11px;
	background-image: url(../../assets/images/icon-reset.svg);
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 50%;
	opacity: .3;
	pointer-events: none;
}

.resetActive {
	opacity: 1;
	cursor: pointer;
	pointer-events: all;
}

@media (max-width: 1010px) {

	.cardGroup, .cardGroupTour, .headingGroup {
		flex-direction: column;
		align-items: center;
	}

	.cardGroupTour {
		max-width: 350px;
	}

	.headingGroup {
		gap: 0;
	}

	.wrapper h3 {
		order: 1;
	}

	.industryYearFilter {
		order: 2;
		margin-top: -14px;
		padding-top: 0;
	}
	
	.mediaTypeFilter {
		order: 3;
		margin-bottom: 15px;
		padding-top: 0;
	}
			
}

@media (max-width: 767px) {

	.wrapper {
		margin-left: 0;
	}
			
}

@media (max-width: 570px) {

	.wrapper {
		min-height: calc(100vh - 215px);
	}

	.cardGroup, .headingGroup {
		padding: 0 30px;
	}

}
