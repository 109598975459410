.background {
	padding: 75px 85px 20px;
	background-color: var(--bg1-color);
}

.header {
	display: flex;
	justify-content: space-between;
	max-width: 1040px;
	margin: 0 auto 96px;
}

.heading {
	padding-bottom: 2px;
	font-size: 36px;
	line-height: 42px;
	color: var(--text-color)
}

.subHeading {
	padding-top: 7px;
	font-size: 14px;
	line-height: 20px;
	color: var(--text-color);
}

.switchesContainer {
	margin-top: 18px;
}

.switchContainer {
	display: flex;
	align-items: center;
	column-gap: 9px;
	margin-bottom: 6px;
	color: var(--text-color);
	cursor: pointer;
}

.barsWrapper {
	max-width: 870px;
	margin: 0 auto 68px;
}

.normControlsWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 45px;
	row-gap: 20px;
	max-width: 870px;
	margin: 0 auto 103px;
}

.normControl {
	display: flex;
	gap: 10px;
	cursor: pointer;
	white-space: nowrap;
}

.selectWrapper {
	display: flex;
	margin-right: -4px;
}

.selectWrapper:after {
	border: solid var(--text-color);
	border-width: 0 1.5px 1.5px 0;
	display: inline-block;
	height: 5px;
	padding-left: 5px;
	margin: 5.5px 8px 0 -15px;
	transform: rotate(45deg);
	content: '';
	pointer-events: none;
}

.select {
	display: block;
	appearance: none;
	background-color: transparent;
	border: none;
	padding-left: 8px;
	padding-right: 22px;
	color: var(--text-color);
	font-size: 14px;
	cursor: pointer;
}

.selectWidth {
	position: absolute;
	height: 0;
	overflow: hidden;
	font-size: 14px;
	color: var(--text-inverse-color)
}

.cardGroupHeading {
	margin-bottom: 26px;
	font-family: 'Greycliff demibold';
	text-align: center;
	color: var(--text-color);
}

.scoreCardsWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 26px;
	row-gap: 26px;
	max-width: 870px;
	margin: 0 auto 80px;
}

@media ((min-width: 662px) and (max-width: 1119px)) {

	.scoreCardsBest {
		flex-direction: column;
		max-width: 422px;
		max-height: 500px;
	}
		
}

@media ((min-width: 768px) and (max-width: 1114px)) {

	.select {
		max-width: none;
	}
		
}

@media (max-width: 980px) {

	.header {
		flex-direction: column;
		margin: 0 auto 74px;
	}

	.switchesContainer {
		margin-top: 24px;
	}
	
}

@media (max-width:767px) {

	.background {
		padding: 65px 40px 10px;
	}

	.heading {
		font-size: 30px;
		line-height: 34px;
	}

	.barsWrapper {
		margin: 0 auto 48px;
	}

	.normControlsWrapper {
		margin: 0 auto 63px;
	}

	.scoreCardsWrapper {
		margin: 0 auto 60px;
	}
}

@media (max-width: 429px) { /* smaller than iPhone 15 Pro max */

	.background {
		padding: 55px 30px 1px;
	}
			
}