.progressContainer {
	margin-bottom: 7px;
}

.backBar {
	width: 100%;
	max-width: 408px;
	height: 11px;
	margin: 0 auto;
	background-color: var(--progressbar-back-color);
	border-radius: 6px;
}

.frontBar {
	height: 11px;
	background-color: var(--progressbar-fill-color);
	border-radius: 6px;
	animation: pulse 1.5s infinite;
}

.full {
	animation: none;
	opacity: 1;
}

.progressLabel {
	width: 100%;
	max-width: 408px;
	text-align: center;
	margin: 15px auto 0;
	color: var(--text-color);
}

@keyframes pulse {
	0%, 100% {
		opacity: 1;
	}
	50% {
		opacity: .6;
	}
}