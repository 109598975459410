.wrapper {
	min-height: calc(100vh - 120px);
	margin-Left: 70px;
	padding-top: 70px;
	box-sizing: border-box;
}

.wrapper h2 {
	margin: 0 auto -5px;
	padding: 0 24px;
	text-align: center;
	color: var(--text-color);
}

@media (max-width: 767px) {

	.wrapper {
		margin-left: 0;
	}
			
}

@media (max-width: 570px) {

	.wrapper {
		min-height: calc(100vh - 215px);
	}

}
