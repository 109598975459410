.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	background-color: var(--bg1-color);
	border-radius: 10px;
	overflow: hidden;
}

.heading {
	margin: 22px 24px 15px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 19px;
	text-align: center;
	color: var(--text-color);
	white-space: nowrap;
}

.itemContainer {
	display: flex;
	align-items: center;
	gap: 15px;
	min-height: 49px;
	margin: 0 24px 10px;
	padding-right: 13px;
	border-radius: 8px;	
	background-color: var(--bg-dashboard-item-color);
	overflow: hidden;
	cursor: pointer;
}

.rank {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 20;
	height: 31px;
	padding: 1px 6px 0;
	font-family: 'Greycliff demibold';
	font-size: 25px;
	text-align: center;
}

.clarity .rank {
	border-right: 1px solid var(--clarity-color);
	color: var(--clarity-color);
}

.brand .rank {
	border-right: 1px solid var(--brand-color);
	color: var(--brand-color);
}

.comms .rank {
	border-right: 1px solid var(--comms-color);
	color: var(--comms-color);
}

.titleContainer {
	flex: 100;
	min-width: 0;
}

.title {
	font-family: 'Greycliff demibold';
	font-size: 14px;
	line-height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.date {
	font-size: 12px;
	line-height: 14px;
	color: var(--text-color);
	opacity: .6;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.scoreBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34px;
	height: 27px;
	padding-top: .5px;
	box-sizing: border-box;
	border-radius: 4px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 21px;
	color: var(--text-inverse-color);
}

.clarity .scoreBox {
	background-color: var(--clarity-color);
}

.brand .scoreBox {
	background-color: var(--brand-color);
}

.comms .scoreBox {
	background-color: var(--comms-color);
}

.noAnalyses {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 155px;
	margin: 0 24px;
	padding-top: 0px;
	box-sizing: border-box;
	border-radius: 8px;
	background-color: var(--bg-dashboard-item-color);
	text-align: center;
}
