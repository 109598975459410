.menuBack {
	position: fixed;
	width: 330px;
	height: 100%;
	top: 0;
	background-color: var(--bg2-color);
	z-index: 9998;
	border-right: 1px solid var(--border-light-color);
	overflow-y: auto;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.06);
	padding: 36px 50px 0;
	box-sizing: border-box;
	transition: left .7s;
}

.sectionContainer {
	border-top: 1px solid var(--border-light-color);
	box-sizing: border-box;
	padding: 35px 0 40px;
}

.heading {
	font-family: 'Greycliff demibold';
}

.label {
	font-size: 12px;
	font-family: 'Greycliff demibold';
	color: var(--text-label-color);
	margin: 17px 0 -1px;
	white-space: nowrap;
}

.value {
	white-space: nowrap;
}

.switchContainer {
	display: flex;
	align-items: center;
	margin-top: 18px;
}

.switchLabel {
	padding-left: 12px;
	line-height: 22px;
	cursor: pointer;
}

.input {
	position: relative;
	width: 100% !important;
	min-width: 230px !important;
	height: 30px !important;
	margin: -2px 0 !important;
	padding: 1px 0 !important;
	background-color: var(--bg2-color) !important;
	border-bottom: 1px solid var(--input-border-color-dark) !important;
}

.inputDisabled {
	border-bottom: 1px solid transparent !important;
	user-select: none !important;
	-webkit-user-select: none !important;
	cursor: default !important;
}

.smallButton {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	min-width: 42px;
	height: 20px;
	border-radius: 11px;
	background-color: var(--bg4-color);
	color: var(--text-inverse-color);
	font-size: 12px;
	font-family: 'Greycliff demibold';
	margin: 6px 0 0 11px;
	padding: 0 11px;
	cursor: pointer;
	transition: background-color .25s, opacity .25s;
}

.smallButton:hover {
	background-color: var(--bg5-color);
}

.smallButtonDisabled {
	opacity: .6;
	pointer-events: none;
}

.infoButton {
	position: relative;
	top: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 1px solid var(--border-medium-color);
	font-size: 12px;
	font-family: 'Greycliff demibold';
	line-height: 12px;
	margin: 0 -25px 4px 10px;
	padding: 1px 0 0;
	cursor: pointer;
}

.infoBox {
	position: absolute;
	margin-top: 5px;
	background-color: var(--bg0-color);
	padding: 11px 18px 13px 13px;
	font-size: 12px;
	line-height: 19px;
	font-family: 'Greycliff demibold';
	border-radius: 5px;
	z-index: 500;
	opacity: 0;
	pointer-events: none;
	transition: opacity .35s;
}

@media (max-width: 767px) {

	.menuBack {
		width: 306px;
		padding: 36px 40px 0;
	}

	.sectionContainer {
		padding: 30px 0 35px;
	}
	
}