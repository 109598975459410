.footerBar {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 120px;
	padding: 35px 50px;
	margin-left: 70px;
	box-sizing: border-box;
	border-top: 1px solid var(--border-light-color);
	text-align: center;
	opacity: .6;
	background-color: var(--bg0-color);
}

p {
	line-height: 24px;
	color: var(--text-color)
}

a {
	text-decoration: none;
	color: var(--text-color);
}

.divider {
	display: inline-block;
	padding: 0 16px;
	opacity: .5;
}

.tablet, .mobile {
	display: none;
}

@media (max-width: 950px) {

	.desktop {
		display: none;
	}

	.tablet {
		display: inline;
	}
	
}

@media (max-width: 767px) {

	.footerBar {
		margin-left: 0;
	}

}

@media (max-width: 570px) {

	.tablet {
		display: none;
	}

	.mobile {
		display: inline;
	}
	
}