.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	background-color: var(--bg1-color);
	border-radius: 10px;
	overflow: hidden;
}

.heading {
	margin: 22px 24px 15px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 19px;
	text-align: center;
}

.newsContainer {
	margin: 0 24px 21px;
	padding: 17px 12px 19px 20px;
	border-radius: 8px;	
	background-color: var(--bg-dashboard-item-color);
	cursor: pointer;
	overflow: hidden;
}

.newsDate {
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 5px;
	opacity: .6;
}

.newsTitle {
	font-family: 'Greycliff demibold';
	font-size: 14px;
	line-height: 20px;
}

.newsText {
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 6px;
}

.cta {
	font-family: 'Greycliff demibold';
	font-size: 12px;
	line-height: 16px;
	color: var(--text-label-green);
}

