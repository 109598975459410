.background {
	margin-bottom: -10px;
	padding: 75px 85px 5px;
}

.header {
	max-width: 1040px;
	margin: 0 auto 77px;
}

.heading {
	padding-bottom: 2px;
	font-size: 36px;
	line-height: 42px;
	color: var(--text-color)
}

.subHeading {
	padding-top: 7px;
	font-size: 14px;
	line-height: 20px;
	color: var(--text-color);
}

.videoWrapper {
	max-width: 870px;
	margin: 0 auto 130px;
}

@media (max-width:899px) {
	
	.header {
		margin: 0 auto 47px;
	}

	.videoWrapper {
		margin: 0 auto 100px;
	}
}

@media (max-width:767px) {

	.background {
		padding: 65px 40px 5px;
	}

	.heading {
		font-size: 30px;
		line-height: 34px;
	}	

}

@media (max-width: 429px) { /* smaller than iPhone 15 Pro max */

	.background {
		padding: 55px 30px 1px;
	}
			
}