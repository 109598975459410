.container {
	margin-left: 70px;
	padding: 41px 30px 80px;
}

.icon {
	margin: 0 30px -5px -6px;
	opacity: .75;
}

.heading {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	min-height: 100px;
	margin: 0 auto 44px;
}

.title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.summary {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 12px;
	max-width: 600px;
	margin: 0 auto 88px;
	padding: 0 35px;
}

.summaryElement {
	padding: 6px 20px 6px;
	border-radius: 8px;
	background-color: var(--bg1-color);
	font-size: 18px;
	line-height: 23px;
}

.button {
	font-family: 'Greycliff demibold';
	font-size: 17px;
	line-height: 20px;
	text-align: center;
	border: 0;
	border-radius: 50px;
	color: #fff;
	margin: 61px 0 0;
	padding: 14px 65px 14px;
	transition: opacity .25s;
	appearance: none;
	outline: none;
	cursor: pointer;
	width: 260px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: var(--bg4-color);
	transition: background-color .25s;
}

.button:hover {
	background-color: var(--bg5-color);
}

.button:disabled {
	opacity: .5;
	cursor: default;
	background-color: var(--bg4-color);
}

.newButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 30px;
	margin: 25px auto -60px;
	font-size: 12px;
	font-family: 'Greycliff demibold';
	color: var(--text-color);
	opacity: .5;
	transition: opacity .2s;
	cursor: pointer;
}

.newButton:hover {
	opacity: 1;
}

@media (max-width: 767px) {

	.container {
		margin-left: 0;
	}

	.icon {
		display: none;
	}
			
}

@media (max-width: 570px) {

	.summary {
		padding: 0;
	}
			
}

