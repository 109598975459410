.accountElementBack {
	padding: 53px 50px 70px;
	border-top: 1px solid var(--border-light-color);
}

.paymentContainer {
	max-width: 1080px;
	margin: 0 auto;
	box-sizing: border-box;
	text-align: center;
	font-size: 18px;
	line-height: 26px;
}

.paymentBox {
	display: flex;
	flex-wrap: wrap;
	column-gap: 50px;
	background-color: var(--bg1-color);
	margin: 27px 0 40px;
	padding: 0 30px 30px;
	border-radius: 10px;
}

.paymentBoxLeft {
	flex: 1;
	min-width: 300px;
	box-sizing: border-box;
	text-align: left;
}

.paymentBoxRight {
	flex: 1;
	min-width: 300px;
	margin: 30px 0 0;
}

.label {
	margin: 24px 0 1px;
	font-size: 12px;
	line-height: 16px;
	font-family: 'Greycliff demibold';
	color: var(--text-label-color);
	white-space: nowrap;
}

.cardElementContainer {
	background-color: var(--bg0-color);
	margin: 0;
	padding: 8px 16px 10px;
	border-radius: 8px;
}

.logoContainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.cardLogos {
	width: 246px;
	height: 30px;
	margin: 30px 20px 0 0;
}

.secureLogos {
	width: 174px;
	height: 42px;
	margin: 20px 0 -2px;
}

@media (max-width: 767px) {

	.accountElementBack {
		padding: 53px 30px 70px;
	}

}

@media (max-width:440px) {

	.accountElementBack {
		padding: 53px 0px 70px;
	}

	.paymentBox {
		border-radius: 0;
	}

	.paymentBoxLeft {
		min-width: 260px;
	}
	
	.paymentBoxRight {
		min-width: 260px;
	}
}