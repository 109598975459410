.accountElementBack {
	padding: 53px 50px 70px;
}

.subscriptionContainer {
	max-width: 1080px;
	margin: 0 auto;
	box-sizing: border-box;
	text-align: center;
	font-size: 18px;
	line-height: 26px;
}

.subColumnsContainer {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	margin: 27px 0 0;
}

.subColumn {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-width: 380px;
}

.subColumnHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	min-height: 63px;
	box-sizing: border-box;
	background-color: var(--bg4-color);
	color: var(--text-inverse-color);
	padding: 14px 30px;
	border-radius: 10px 10px 0 0;
	font-family: 'Greycliff demibold';
	text-align: left;
}

.subColumnHeader h3 {
	line-height: 28px;
}

.subColumnBody {
	flex: 1;
	background-color: var(--bg1-color);
	border-radius: 0 0 10px 10px;
	padding: 15px 30px 18px;
	text-align: left;
}

.subColumnDetails {
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	line-height: 39px;
	white-space: nowrap;
}

.label {
	font-size:12px;
	line-height:16px;
	font-family: 'Greycliff demibold';
	color: var(--text-label-color);
	white-space: nowrap;
}

.subData {
	margin-bottom: 12px;
	color: var(--text-color);
	white-space: nowrap;
}

.accountText {
	margin: 16px 0 -5px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
}

.link {
	color: var(--text-link-color);
	text-decoration: none;
}

@media (max-width: 767px) {

	.accountElementBack {
		padding: 53px 30px 70px;
	}

}

@media (max-width:440px) {

	.accountElementBack {
		padding: 53px 0px 70px;
	}

	.subColumn {
		min-width: auto;
	}

	.subColumnHeader, .subColumnBody {
		border-radius: 0;
	}

	.accountText {
		margin: 16px 30px -5px;
	}

}