.accountElementBack {
	padding: 53px 50px 70px;
	border-top: 1px solid var(--border-light-color);
}

.invoiceContainer {
	max-width: 1080px;
	margin: 0 auto;
	box-sizing: border-box;
	text-align: center;
	font-size: 18px;
	line-height: 26px;
}

.invoiceBox {
	display: flex;
	flex-wrap: wrap;
	background-color: var(--bg1-color);
	margin: 27px 0 10px;
	padding: 24px 30px 26px;
	border-radius: 10px;
}

.invoiceTable {
	width: 100%;
	border: 0;
	margin: 0;
	padding: 0;
	text-align: left;
}

.td:not(:last-child) {
	padding: 7px 12px 0 0;
	white-space: nowrap;
	line-height: 28px;
	text-transform: capitalize;
}

.label {
	font-size: 12px;
	line-height: 20px;
	font-family: 'Greycliff demibold';
	color: var(--text-label-color);
	white-space: nowrap;
	text-transform: none;
}

.downloadLink img {
	transform: translate(0, 8px);
}

@media (max-width: 889px) {

	.td {
		display: table-row;
	}

	.tr:not(:last-child) {
		display: block;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid var(--border-light-color);
	}

	.label {
		display: none;
	}

	.td:before {
		display: inline-block;
		width: 100px;
		font-size: 12px;
		font-family: 'Greycliff demibold';
		color: var(--text-label-color);
		text-transform: none;
	}

	.date:before {
		content: 'Date:';
	}

	.product:before {
		content: 'Product:';
	}

	.card:before {
		content: 'Credit card:';
	}

	.amount:before {
		content: 'Amount:';
	}

	.no:before {
		content: 'Invoice No.:';
	}

	.status:before {
		content: 'Status:';
	}

	.download:before {
		content: 'Download:';
	}
	
	.downloadLink img {
		transform: translate(-6px, 5px);
	}
}

@media (max-width: 767px) {

	.accountElementBack {
		padding: 53px 30px 70px;
	}

}
@media (max-width:440px) {

	.accountElementBack {
		padding: 53px 0px 70px;
	}

	.invoiceBox {
		border-radius: 0;
	}

}