.container {
	width: 100%;
	min-height: calc(100vh - 120px);
	padding: 70px 50px 50px;
	text-align: center;
	box-sizing: border-box;
	background-color: var(--bg1-color);
}

.analysisList {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 2000px;
	margin: 0 auto;
	padding-top: 20px;
}

.searchContainer {
	display: flex;
	justify-content: center;
	position: relative;
	max-width: 250px;
	height: 20px;
	box-sizing: border-box;
	margin: 29px auto -38px;
}

.searchInput {
	width: 100%;
	height: 20px;
	margin: 0;
	padding: 0;
	-webkit-appearance: none;
	background-color: var(--bg0-color);
	border-radius: 10px;
	border: none;
	font-size: 12px;
	text-align: center;
}

.clearButton {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -20px;
	padding-bottom: 1px;
	font-family: 'Greycliff';
	background-color: var(--bg0-color);
	font-size: 24px;
	color: var(--text-label-color);
	box-sizing: border-box;
	min-width: 20px;
	height: 20px;
	border-radius: 11px;
	cursor: pointer;
}

.searchIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -20px;
	padding-right: 2px;
	font-family: 'Greycliff';
	background-color: var(--bg0-color);
	font-size: 20px;
	color: var(--text-label-color);
	box-sizing: border-box;
	min-width: 20px;
	height: 20px;
	border-radius: 11px;
	transform: rotate(-45deg);
}

/* filters */

.filters {
	display: flex;
	gap: 30px;
	margin: 0 auto;
	max-width: 1820px;
}

.industryYearFilter {
	flex: 1;
	padding-top: 18px;
}

.menuGroupWrapper {
	display: flex;
	gap: 15px;
}

.mediaTypeFilter {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	flex: 1;
	padding-top: 18px;
}

.selectWrapper {
	display: flex;
	align-self: flex-end;
	height: 20px;
	margin: 6px -4px 0 0;
}

.selectWrapper:after {
	border: solid var(--text-color);
	border-width: 0 1.5px 1.5px 0;
	display: inline-block;
	height: 5px;
	padding-left: 5px;
	margin: 5.5px 8px 0 -15px;
	transform: rotate(45deg);
	content: '';
	pointer-events: none;
}

.select {
	display: block;
	appearance: none;
	background-color: transparent;
	border: none;
	padding-left: 3px;
	padding-right: 22px;
	color: var(--text-color);
	font-size: 12px;
	cursor: pointer;
}

.selectWidth {
	position: absolute;
	height: 0;
	overflow: hidden;
	font-size: 12px;
	color: var(--text-inverse-color);
}

.selected {
	font-family: 'Greycliff demibold';
}

.reset {
	min-width: 20px;
	height: 20px;
	margin: 6px -3px -6px -2px;
	border-radius: 11px;
	background-image: url(../../assets/images/icon-reset.svg);
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 50%;
	opacity: .3;
	pointer-events: none;
}

.resetActive {
	opacity: 1;
	cursor: pointer;
	pointer-events: all;
}

@media (max-width: 2029px) {

	.filters {
		max-width: 1450px;
	}

}

@media (max-width: 1659px) {

	.filters {
		max-width: 1080px;
	}

}

@media (max-width: 1289px) {

	.filters {
		max-width: 710px;
	}

	.searchContainer {
		max-width: 160px;
	}

}

@media (max-width: 919px) {

	.filters {
		flex-direction: column;
		align-items: center;
		gap: 0;
	}

	.searchContainer {
		margin: 80px auto -83px;
		max-width: 246px;
	}

	.industryYearFilter {
		order: 1;
		margin-top: -4px;
		padding-top: 0;
	}
	
	.mediaTypeFilter {
		order: 2;
		margin-bottom: 41px;
		padding-top: 0;
	}
			
}

@media (max-width: 570px) {

	.container {
		min-height: calc(100vh - 215px);
	}

	.filters {
		padding: 0 30px;
	}

}
