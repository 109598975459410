.dropZone {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	box-sizing: border-box;
	background-image: url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'10\' ry=\'10\' stroke=\'%232d354466\' stroke-width=\'4\' stroke-dasharray=\'1%2c 11\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");
	background-color: var(--bg1-color);
	border-radius: 10px;
	color: var(--text-color);
	cursor: pointer;
}

.dropZone .icons {
	width: 120px;
	height: 50px;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('../../../assets/images/media-type-icons/icon-new-analysis-blue.svg');
	margin-bottom: 13px;
}

.dropZone h4 {
	margin-bottom: 2px;
	padding: 0 40px;
	text-align: center;
	pointer-events: none;
	color: var(--text-color);
	font-family: 'Greycliff demibold';
	line-height: 23px;
}

.dropZone p {
	text-align: center;
	pointer-events: none;
	color: var(--text-label-green);
	font-family: 'Greycliff demibold';
	font-size: 12px;
	line-height: 16px;
}

.dropZoneHighlight {
	background-color: var(--bg2-color);
}

.dropZoneHighlight h4 {
	font-family: 'Greycliff';
}

.dropZone .dropZoneError {
	color: var(--error-color);
	font-family: 'Greycliff';
}