html {
	overflow-x: hidden; /* prevent horizontal bounce on mobile when dragging video controller */
}

.wrapper {
	min-height: calc(100vh - 120px);
	margin-left: 70px;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	min-height: calc(100vh - 120px);
	padding: 0 30px;
}

@media (max-width: 767px) {

	.wrapper {
		margin-left: 0;
	}

}

@media (max-width: 570px) {

	.wrapper {
		min-height: calc(100vh - 215px);
	}

	.container {
		min-height: calc(100vh - 215px);
	}

}