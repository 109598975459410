.btnContainer {
	display: flex;
	justify-content: space-between;
	width: 600px;
	margin: 30px auto;
}

.canvas {
	display: block;
	margin: 0 auto;
	box-shadow: 0 0 0 1px var(--border-video-color);
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 42px;
	padding-top: 1px;
	font-family: 'Greycliff demibold';
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: .3px;
	border-radius: 30px;
	cursor: pointer;
	box-sizing: border-box;
}

.arrowLeft {
	width: 160px;
	padding-left: 28px;
	background-image: url(../../../assets/images/easy-tag-arrow-left.svg);
	background-repeat: no-repeat;
	background-position: 9% 9px;
}

.arrowRight {
	width: 160px;
	padding-right: 26px;
	background-image: url(../../../assets/images/easy-tag-arrow-right.svg);
	background-repeat: no-repeat;
	background-position: 91% 9px;
}

.tagBtn {
	width: 220px;
}
