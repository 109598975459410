.sectionHeading {
	font-size: 23px;
	line-height: 26px;
	font-family: 'Greycliff Demibold';
	padding: 34px 24px 31px;
	border-top: 1px solid var(--border-light-color);
}

.container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 24px;
	margin: 0 auto;
	padding: 0 40px 48px;
}

.typeButton {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	width: 140px;
	height: 80px;
	box-sizing: border-box;
	margin-bottom: 8px;
	background-color: var(--bg1-color);
	background-repeat: no-repeat;
	border-radius: 10px;
	cursor: pointer;
	transition: background-color .2s;
}

.typeButton:hover {
	background-color: var(--bg2-color);
}

.typeButtonInverse {
	background-color: var(--bg3-color);
	cursor: default;
}

.typeButtonInverse:hover {
	background-color: var(--bg3-color);
}

.typeLabel {
	font-size: 18px;
}

@media (max-width: 1119px) {

	.container {
		max-width: 700px;
	}
			
}

@media (max-width: 791px) {

	.container {
		max-width: 500px;
	}
			
}