.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	background-color: var(--bg1-color);
	border-radius: 10px;
	overflow: hidden;
}

.heading {
	margin: 22px 24px 15px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 19px;
	text-align: center;
}

.itemContainer {
	display: flex;
	align-items: center;
	gap: 20px;
	min-height: 70px;
	margin: 0 24px 15px;
	border-radius: 8px;	
	background-color: var(--bg-dashboard-item-color);
	overflow: hidden;
	cursor: pointer;
}

.thumb {
	flex: 10;
	height: 100%;
	background-color: var(--bg2-color);
	background-position: center;
}

.titleContainer {
	flex: 14;
	min-width: 0;
	margin-right: 16px;
}

.title {
	font-family: 'Greycliff demibold';
	font-size: 14px;
	line-height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cta {
	font-family: 'Greycliff demibold';
	font-size: 12px;
	line-height: 16px;
	color: var(--text-label-green);
}

.noAnalyses {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 155px;
	margin: 0 24px;
	padding-top: 0px;
	box-sizing: border-box;
	border-radius: 8px;
	background-color: var(--bg-dashboard-item-color);
	text-align: center;
}
