.container {
	display: flex;
	flex-direction: column;
	flex: 2 710px;
	width: 100%;
	min-width: 530px;
	max-width: 730px;
	height: 244px;
	background-color: var(--bg1-color);
	border-radius: 10px;
	overflow: hidden;
}

.heading {
	margin: 22px 24px 15px;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	line-height: 19px;
	text-align: center;
}

.benchmarkContainer {
	display: flex;
	height: 140px;
	margin: 0 24px 16px;
	border-radius: 8px;	
	background-color: var(--bg-dashboard-item-color);
	box-sizing: border-box;
}

.arrow {
	flex: 1;
	background-repeat: no-repeat;
	cursor: pointer;
}

.arrowLeft {
	background-image: url('../../../assets/images/benchmark-slider-arrow-left.svg');
	background-position: 60% center;
}

.arrowRight {
	background-image: url('../../../assets/images/benchmark-slider-arrow-right.svg');
	background-position: 40% center;
}

.slidesContainer {
	display: flex;
	flex: 6;
	overflow: hidden;
}

.slider {
	display: flex;
	min-width: 300%;
	position: relative;
}

.dotsContainer {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-bottom: 20px;
}

.dot {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	box-sizing: border-box;
	cursor: pointer;
}

.dot.clarity {
	border: 2px solid var(--clarity-color);
}

.dot.brand {
	border: 2px solid var(--brand-color);
}

.dot.comms {
	border: 2px solid var(--comms-color);
}

.dot.clarity.active {
	background-color: var(--clarity-color);
	cursor: default;
}

.dot.brand.active {
	background-color: var(--brand-color);
	cursor: default;
}

.dot.comms.active {
	background-color: var(--comms-color);
	cursor: default;
}

@media (max-width: 1010px) {
	
	.container {
		flex: auto;
		min-width: 250px;
		max-width: 350px;
		height: auto;
	}

	.benchmarkContainer {
		height: auto;
		padding: 12px 0 16px;
	}
	
	.arrow {
		flex: 3;
	}
	
}
