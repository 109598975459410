.sideBar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	width: 70px;
	height: 100%;
	margin-left: 0;
	padding: 23px 6px;
	box-sizing: border-box;
	background-color: var(--bg3-color);
	z-index: 9999;
	transition: margin-left .4s;
}

.sideBar.mobileShow {
	margin-left: 0;
}

.menuContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flowsamIcon {
	width: 36px;
	height: 36px;
	cursor: pointer;
}

.menuIcon {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	cursor: pointer;
	color: var(--text-inverse-color);
	font-size: 12px;
	line-height: 15px;
	font-family: 'Greycliff demibold';
	margin-top: 22px;
}

.menuDivider {
	background-color: var(--bg0-color);
	margin-top: 28px;
	width: 38px;
	height: 1px;
}

.avatar {
	width: 36px;
	height: 36px;
	border: 1px solid var(--bg0-color);
	border-radius: 50%;
	box-sizing: border-box;
	background-color: var(--bg0-color);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	margin-bottom: 7px;
}

.mobileIcon {
	display: none;
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-image: url('../../assets/images/icon-nav-mobile-show.svg');
	background-repeat: no-repeat;
	background-position: 50%;
	top: 21px;
	left: 91px;
}

.mobileShow .mobileIcon {
	background-image: url('../../assets/images/icon-nav-mobile-hide.svg');
}

@media (max-width: 767px) {

	.sideBar {
		margin-left: -70px;
	}

	.mobileIcon {
		display: block;
	}
}