.sectionHeading {
	font-size: 23px;
	line-height: 26px;
	font-family: 'Greycliff Demibold';
	padding: 34px 24px 31px;
	border-top: 1px solid var(--border-light-color);
}

.container {
	display: flex;
	justify-content: center;
	row-gap: 13px;
	column-gap: 24px;
	margin: 0 auto;
	padding: 0 40px 55px;
	text-align: left;
}

.formColumn {
	display: flex;
	flex-direction: column;
	row-gap: 13px;
	width: 100%;
	max-width: 340px;
}

.inputLabel {
	color: var(--text-color);
	padding-left: 2px;
	margin-bottom: 3px;
}

.inputText {
	border: none;
	border-radius: 8px;
	background-color: var(--bg1-color);
	width: 100%;
	height: 47px;
	padding: 0 20px 1px;
	box-sizing: border-box;
	color: var(--text-color);
	font-size: 18px;
	line-height: 24px;
	-webkit-appearance: none;
	appearance: none;
}

.inputTextArea {
	height: 130px;
	padding: 11.5px 20px 0;
	vertical-align: top;
	resize: none;
}

.AnalysisTypeContainer {
	display: flex;
	align-items: flex-start;
	column-gap: 4px;
	border-radius: 8px;
	background-color: var(--bg1-color);
	width: 100%;
	height: 47px;
	box-sizing: border-box;
}

.switchContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 10px;
	width: 100%;
	height: 47px;
	padding-left: 30px;
	color: var(--text-color);
	font-size: 14px;
	line-height: 16px;
}

.switchContainer .enabled {
	opacity: 1;
	cursor: default;
	pointer-events: none;
	transition: opacity .4s;
}

.switchContainer .disabled {
	opacity: .6;
	cursor: pointer;
	pointer-events: all;
	transition: opacity .4s;
}

.submitButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 256px;
	height: 48px;
	border-radius: 25px;
	margin: 0 auto 80px;
	background-color: var(--bg4-color);
	color: var(--text-inverse-color);
	font-family: 'Greycliff Demibold';
	font-size: 17px;
	line-height: 22px;
	cursor: pointer;
	transition: background-color .25s;
}

.submitButton:hover {
	background-color: var(--bg5-color);
}

.submitButton.disabled {
	opacity: .3;
	cursor: default;
	background-color: var(--bg4-color);
}

@media (max-width: 767px) {

	.container {
		flex-wrap: wrap;
	}

	.formColumn {
		max-width: 468px;
	}
			
}