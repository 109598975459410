.uploadField {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	background-image: url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'10\' ry=\'10\' stroke=\'%232d354466\' stroke-width=\'4\' stroke-dasharray=\'1%2c 11\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");
	border-radius: 10px;
	margin: 0 auto;
	color: var(--text-color);
	padding: 26px 30px 30px;
	box-sizing: border-box;
	cursor: pointer;
	overflow: hidden;
	z-index: 99;
}

.preview {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 250px;
	max-width: 350px;
	height: 244px;
	background-color: var(--bg1-color);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-origin: content-box;
	border-radius: 10px;
	margin: 0 auto -244px;
	padding: 10px;
	box-sizing: border-box;
	overflow: hidden;
	z-index: 98;
}

.previewVideo {
	width: 100%;
	max-height: 225px;
	pointer-events: none;
}

.icon {
	width: 120px;
	height: 50px;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('../../../../assets/images/media-type-icons/icon-new-analysis-blue.svg');
	margin: 0 auto 13px;
}

.newCta {
	text-align: center;
	pointer-events: none;
	color: var(--text-label-green);
	font-family: 'Greycliff demibold';
	font-size: 12px;
	line-height: 16px;
}
