.background {
	margin-top: 10px;
	padding: 75px 85px 26px;
	background-color: var(--bg1-color);
}

.header {
	display: flex;
	justify-content: space-between;
	max-width: 1040px;
	margin: 0 auto 78px;
}

.heading {
	padding-bottom: 2px;
	font-size: 36px;
	line-height: 42px;
	color: var(--text-color)
}

.subHeading {
	padding-top: 7px;
	font-size: 14px;
	line-height: 20px;
	color: var(--text-color);
}

.switchesContainer {
	margin-top: 18px;
}

.switchContainer {
	display: flex;
	align-items: center;
	column-gap: 9px;
	margin-bottom: 6px;
	color: var(--text-color);
	cursor: auto;
}

.enabled {
	opacity: 1;
	cursor: default;
	pointer-events: none;
}

.disabled {
	opacity: .6;
	cursor: pointer;
	pointer-events: all;
}

.enabled:before, .disabled:before {
	content: 'Group by a'
}

.barsWrapper {
	max-width: 870px;
	margin: 0 auto 68px;
}

.headerRow {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.columnLabel {
	font-size: 12px;
	color: var(--text-label-blue);
}

.columnHeading {
	padding: 0 65px 3px 0;
	margin-left: -30px;
	text-align: center;
	font-family: 'Greycliff demibold';
	font-size: 18px;
	color: var(--text-color)
}

.col1 {
	min-width: 82px;
}

.col2 {
	min-width: 116px;
}

.col3 {
	width: 100%;
	min-width: 80px;
}

.col4 {
	min-width: 110px;
}

.showAll, .showLimited {
	margin: -2px 0 -8px;
	font-size: 12px;
	line-height: 10px;
	text-align: center;
	cursor: pointer;
	color: var(--text-label-blue);
}

.showAll:after {
	border: solid var(--text-label-blue);
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 2.5px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	content: '';
}

.showLimited {
	line-height: 12px;
}

.showLimited:before {
	border: solid var(--text-label-blue);
	border-width: 1px 0 0 1px;
	display: inline-block;
	padding: 2.5px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	content: '';
}

.noAssets {
	padding-bottom: 10px;
	text-align: center;
	font-size: 12px;
	color: var(--text-label-blue);
}

@media (max-width: 980px) {

	.header {
		flex-direction: column;
		margin: 0 auto 58px;
	}

	.switchesContainer {
		margin-top: 24px;
	}
	
}

@media (max-width: 767px) {

	.background {
		padding: 65px 40px 16px;
	}
	
	.header {
		margin: 0 auto 38px;
	}

	.heading {
		font-size: 30px;
		line-height: 34px;
	}	

	.enabled:before, .disabled:before {
		content: 'A'
	}

	.barsWrapper {
		margin: 0 auto 58px;
	}
	
	.col1, .col2, .col4 {
		display: none;
	}

	.col3 {
		margin-left: auto;
		margin-right: auto;
	}

	.columnHeading {
		padding-right: 0;
	}
	
}

@media (max-width: 429px) { /* smaller than iPhone 15 Pro max */

	.background {
		padding: 55px 30px 6px;
	}
			
}